@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,700;1,400&display=swap');

:root {
    --border: #d4d7d9;
    --secondary: #A8A8A8;
}

$primary: #7856FF;

@import "../node_modules/bootstrap/scss/bootstrap";

// Required
@import "../node_modules/bootstrap/scss/functions";

// Default variable overrides

$font-family-base: 'Poppins';
$font-size-base: 16px;
$headings-font-family: 'Merriweather';
$body-bg: #fff;
$body-color: #000;

// Required
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

// Optional Bootstrap components here
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
// etc