@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

html{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

body {
    /* display: flex; */
    cursor: default;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: auto auto;
    background-color: #3A4A6E;;
}

html, body, #root{
    height: 100%;
}

a.navi {
    font-weight: 700;
    color: black;
    text-decoration: none;
    padding: .8rem 1rem;
    background-color: white;
}

a.navi:hover{
    background-color: #EEEEEE;
}

.offcanvas {
    background-color: #F2F3FF;
}

.text-smaller {
    font-size: smaller
}