.summary-wrapper {
    width: 100% !important;
    display: block !important;
    /* height: 80% !important; */
  }

  .summary-editor {
    height: 70vh;
    border: 1px solid #F1F1F1 !important;
    padding: .5rem !important;
    border-radius: .25rem;
    cursor: text;
  }

  /* .public-DraftStyleDefault-block{
    margin: 0;
  } */