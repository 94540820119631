.uploader {
    border-radius: 1rem;
    border-width: 1px;
    border-color: var(--border);
    border-style: solid;
    width: 100%;
    background-color: white;
}

.uploader-files{ margin: 2rem 1rem 0 1rem; }

.uploader-line{
    height: 1px;
    background-color: var(--border);
}

.uploader-top{
    padding: 1em 1em 2em;
}

.uploader-item{
    padding: 1em 1em;
}

.uploader-bottom{    
    text-align: center;
    padding: 1.5em;
}

.form-check{
    min-height: auto;
    margin-bottom: auto;
}

.uploader textarea {
    resize: none;
    border: none;
    height: 5em;
}
